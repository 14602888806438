import React from 'react';
import { SubHeading } from '../../components';

import { images } from '../../constants';

import './Header.css';

const Header = () => (
  <div className='app__header app__wrapper section__padding' id="home">
    <div className='app__wrapper_info'>
      <SubHeading title="Chassez la nouvelle saveur" />
      <h1 className='app__header-h1'>La clé de la gastronomie</h1>
      <p className='p__opensans' style={{margin: '2rem 0'}}>
        Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.
      </p>
    </div>
    <div className='app__wrapper_img'>
      <img src={images.welcome} alt="heading img" />
    </div>
  </div>
);

export default Header;
