import React from 'react';
import { SubHeading, MenuItem } from '../../components';
import { images, data } from '../../constants';
import './SpecialMenu.css';

const SpecialMenu = () => (
  <div className='app__specialMenu flex__center section__padding' id="menu">
    <div className='app__specialMenu-title'>
      <SubHeading title="Un menu qui s'adapte à votre palais" />
      <h1 className='headtext__cormorant'>Nos spécialités aujourd'hui</h1>
    </div>

    <div className='app__specialMenu-menu'>
        <div className='app__specialMenu-menu_wine flex__center'>
          <p className='app__specialMenu-menu_heading'>Vin & Bières</p>
          <div className='app__specialMenu_menu_items'>
              {data.wines.map((wine, index) => (
                <MenuItem key={wine.title + index} price={wine.price} title={wine.title} tag={wine.tags}/>
              ))}
          </div>
        </div>

        <div className='app__specialMenu-menu_img'>
            <img src={images.menu} alt="menu img" />
        </div>   

        <div className='app__specialMenu-menu_cocktails flex__center'>
          <p className='app__specialMenu-menu_heading'>Cocktails</p>
          <div className='app__specialMenu_menu_items'>
              {data.cocktails.map((cocktail, index) => (
                <MenuItem key={cocktail.title + index} price={cocktail.price} title={cocktail.title} tag={cocktail.tags}/>
              ))}
          </div>
        </div>
    </div>
  </div>
);

export default SpecialMenu;
