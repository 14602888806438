import React from 'react';
import { SubHeading } from '../../components';
import { images } from '../../constants';

const FindUs = () => (
  <div className='app__bg app__wrapper section__padding' id="contact">
    <div className='app__wrapper_info'>
      <SubHeading title="Contact" />
      <h1 className='headtext__cormorant' style={{marginBottom: '3rem'}}>Nous trouver</h1>
      <div className='app__wrapper-content'>
        <p className='p__opensans'>536, chemin Laetitia Legros, 15 502 Jacquotdan</p>
        <p className='p__cormorant' style={{color: '#DCCA87', margin: '2rem 0'}}>Horaire d'ouverture</p>
        <p className='p__opensans'>Lun-Vend: 10h-02h</p>
        <p className='p__opensans'>Sam-Dim: 10h-3h</p>
      </div>
    </div>

    <div className='app__wrapper_img'>
      <img src={images.findus} alt="findus" />
    </div>
  </div>
);

export default FindUs;
