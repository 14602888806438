import React from 'react';
import {FiFacebook, FiTwitter, FiInstagram} from 'react-icons/fi';
import { images } from "../../constants"

import { FooterOverlay, Newsletter } from '../../components'
import './Footer.css';

const Footer = () => (
  <div className='app__footer section__padding'>
    <FooterOverlay />
    <Newsletter />

    <div className='app__footer-links'>
      <div className='app__footer-links_contact'>
        <h1 className='app__footer-headtext'>Contactez nous</h1>
        <p className='p__opensans'>
          536, chemin Laetitia Legros, 15 502 Jacquotdan
        </p>
        <p className='p__opensans'>
            +33 (0)8 18 49 26 91
        </p>
      </div>
      <div className='app__footer-links_logo'>
      <h1 className='app__footer-headtext'>BWS Restaurant</h1>
          <p className='p__opensans'>"Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam."</p>
          <img src={images.spoon} alt="spoon" className='spoon__img' style={{marginTop: 15}} />
          <div className='app__footer-links_icons'>
            <FiFacebook />
            <FiTwitter />
            <FiInstagram />
          </div>
      </div>
      <div className='app__footer-links_work'>
        <h1 className='app__footer-headtext'>Horaire d'ouverture</h1>
        <p className='p__opensans'>
          Lundi-Vendredi:
        </p>
        <p className='p__opensans'>
            10h - 02h
        </p>
        <p className='p__opensans'>
          Samedi-Dimanche:
        </p>
        <p className='p__opensans'>
            10h - 03h
        </p>
      </div>
    </div>
    <div className='footer_copyright'>
      <p className='p__opensans'>2022 BWS Restaurant. All Rights reserved.</p>
    </div>
  </div>
);

export default Footer;
